.parallax {
  perspective: 300px;
  -webkit-perspective: 300px;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;

  &__group {
    position: relative;
    height: 1000px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  &__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &--fore {
      transform: translateZ(0px) scale(.8);
      z-index: 1;
    }

    &--base {
      transform: translateZ(-150px) scale(1.1);
      z-index: 4;
    }

    &--back {
      transform: translateZ(-300px) scale(1.6);
      z-index: 3;
    }

    &--deep {
      transform: translateZ(-450px) scale(2.4);
      z-index: 2;
    }
  }
}

.js-parallax-position {
  left: 50%;
  transform: translate(-50%);
}
