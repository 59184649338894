.media-stack {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media #{$media-md} {
    flex-direction: row;
    align-items: flex-start;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--none {
    flex-direction: row;
    align-items: flex-start;
  }

  &__object {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    flex: 0 0 225px;
    max-width: 225px;

    @media #{$media-md} {
      display: block;
      margin-bottom: 0;
    }

    @media #{$media-screen-mobile} {
      width: 100%;
    }

    .media-stack--none & {
      display: block;
      margin-bottom: 0;
    }

    &.media-stack__object--img {
      img {
        width: 225px;
        margin: auto;
      }
    }

    &.media-stack__object--icon {
      flex: 0 0 120px;
      max-width: 120px;

      img {
        width: 120px;
      }
    }

    img {
      @media #{$media-md} {
        max-width: inherit;
      }
    }
  }

  &__text {
    margin-left: 0;
    color: $color-tertiary;

    .media-stack--none & {
      margin-left: $grid-gutter-width;
    }

    @media #{$media-md} {
      margin-left: $grid-gutter-width;
    }
  }

}
