.search-result-page {
  margin-top: 1rem;
  margin-bottom: 2rem;

  &__title {
    color: $color-tertiary;
    padding-bottom: .5rem;
    display: block;
    text-decoration: none;
    font-size: 22px;

    &:hover {
      text-decoration: underline;
    }
  }
}
