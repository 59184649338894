.calc-step-1 {
  &__seclect-option {
    @extend .career-form-submit__full-name;
    padding: 20px 0;
    margin-right: 0;

    .dropdown {
      width: 100%;
      background: $color-white;
      position: relative;
    }

    option {
      width: 100%;
    }
  }

  .input-error {
    color: $color-errors;
  }
}
