.breadcrumbs {
  padding: 0;
  color: $color-primary;

  & a ,& .active{
    text-decoration: none;
    color: $color-text-body;
  }

  & .icon-arrow-right:before {
    color: $color-text-body;
    font-size: map-get($font-size, "size-8");
    margin: 0 3px;
  }
}
