.media-gallery {
  & .grid-item {
    float: left;
    display: flex;
    cursor: pointer;
    overflow: hidden;

    &--inner {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      transition: transform 0.5s ease;
      overflow: hidden;
      padding-bottom: 56.2%;

      &:hover {
        transform: scale(1.2);
        z-index: 1;
      }
    }

    img {
      width: 100%;
      height: auto;
      transition: transform .5s ease;

      &:hover {
        transform: scale(1.2);
        z-index: 1;
      }
    }
  }

  .grid-sizer {
    width: 10px;
  }

  & .grid-item--width1 {
    width: calc(25% - 10px);
  }

  & .grid-item--width2 {
    width: calc(50% - 10px);
  }

  &__modal {
    & .modal-wrapper {
      &:focus {
        outline: none;
      }
    }

    & .modal-content {
      min-height: 500px;
    }

    & .modal-content-title {
      font-size: 18px;
      line-height: 1;
      color: $color-tertiary;
      border-bottom: 1px solid $color-spacing-text;
      padding-bottom: 20px;
    }

    & .modal-item {
      padding: 20px 0 20px 0;
    }

    & .modal-item,
    & .modal-bottom {
      display: flex;
      flex-direction: row;
    }

    & .modal-bottom {
      padding-bottom: 15px;
    }

    &-dialog {
      max-width: 1036px;
      position: relative;
      margin: 0 auto;
    }

    & .modal-content-image {
      flex: 0 0 60%;
      padding-right: 15px;

      & img {
        width: 100%;
        height: auto;
      }
    }

    & .modal-button-left {
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-end;
    }

    & .modal-content-body {
      flex: 0 0 40%;
      padding-left: 15px;
    }

    & .modal-close {
      right: 15px;
      top: 15px;
    }

    & .modal-content-body-title {
      padding: 0 10px;
    }

    & .modal-slick-prev span,
    & .modal-slick-next span {
      background: $color-spacing-text;
      color: $color-white;
      padding: 5px;
      font-size: 18px;
      display: inline-block;
      cursor: pointer;

      &:hover {
        background: $color-tertiary;
      }
    }

    & .modal-image,
    & .embed-responsive-16by9::before {
      background-position: center;
      background-size: cover;
      padding-top: 75%;
    }
  }

  @media #{$media-screen-mobile} {
    & .grid {
      margin-right: -10px;
    }

    & .grid-item {
      width: 100%;
    }

    & .grid-item--width1 {
      width: calc(50% - 10px);
    }

    & .grid-item--width2 {
      width: calc(100% - 10px);
    }

    &__modal {
      & .modal-bottom {
        margin: 0;
        justify-content: center;
      }

      & .modal-dialog {
        margin-top: 1rem;
        height: auto;
      }

      & .modal-content-title {
        padding-bottom: 15px;
      }

      &-dialog {
        height: auto;
      }

      & .modal-item {
        flex-direction: column;
        padding: 15px 0 15px 0;
      }

      & .modal-content-image,
      & .modal-content-body {
        padding: 0;
      }

      & .modal-button-left {
        flex: 0 0 auto;
        padding: 0;
      }

      & .modal-content-body {
        padding-top: 20px;
      }
    }
  }

  @media #{$media-screen-mobile-small} {
    &__modal {
      .modal {
        & .modal-close {
          right: 5px;
        }

        & .modal-content {
          padding: 0 20px;
        }
      }
    }
  }
}
