.no-m {
  margin: 0;
}

.no-tm {
  margin-top: 0;
}

.no-bm {
  margin-bottom: 0;
}

.no-p {
  padding: 0;
}

.no-lp {
  padding-left: 0;
}

.clear-fix {
  clear: both;
}
