.image-inline-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
  text-align: center;

  &__item {
    flex-grow: 1;
    flex-basis: percentage(6/12);
    margin-bottom: 1rem;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;

    @media #{$media-sm} {
      flex-basis: percentage(4/12);
    }

    @media #{$media-md} {
      flex-basis: percentage(3/12);
    }

    @media #{$media-lg} {
      flex-basis: percentage(2/12);
    }
  }
}
