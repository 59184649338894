$color-primary: #16b09c;
$color-secondary: #eb6028;
$color-tertiary: #003949;

$theme-colors: (
  "primary": $color-primary,
  "secondary": $color-secondary,
  "tertiary": $color-tertiary,
);

$color-hover-lighten: 10%;
$color-active-lighten: 20%;

$hover-colors: (
  "primary": #5eb0a2,
  "secondary": #f2844f,
  "tertiary": #365363
);

$active-colors: (
  "primary": #c0e1da,
  "secondary": #fbc6a7,
  "tertiary": #939da9
);

$color-white:    #fff !default;
$color-gray-100: #f3f3f3 !default;
// $color-gray-200: #e9ecef !default;
$color-gray-300: #dee2e6 !default;
// $color-gray-400: #ced4da !default;
$color-gray-500: #b3b3b3 !default;
 $color-gray-600: #6c757d !default;
$color-gray-700: #454545 !default;
$color-gray-800: #393939 !default;
// $color-gray-900: #212529 !default;
$color-black:    #000 !default;

$grays: (
  "100": $color-gray-100,
  // "200": $color-gray-200,
  "300": $color-gray-300,
  // "400": $color-gray-400,
  "500": $color-gray-500,
   "600": $color-gray-600,
  "700": $color-gray-700,
  "800": $color-gray-800,
  // "900": $color-gray-900
);

$backgrounds: (
  "primary": $color-primary,
  "secondary": $color-secondary,
  "tertiary": $color-tertiary,
  "gray-100": $color-gray-100,
);

$body-color: $color-gray-800;
$color-text-body: $color-gray-800;
$color-bg-footer: $color-gray-800;

$color-input-placeholder: $color-gray-500;

$color-text-nav-search: $color-primary;
$color-navigation-background: $color-tertiary;
$color-sub-item-menu: $color-tertiary;
$color-sub-item-mobile: $color-tertiary;
$color-show-menu-background: $color-gray-100;
$color-bg-text-banner: $color-primary;
$color-social-icon: $color-gray-800;

$color-bg-text: $color-secondary;

$color-bg-footer: $color-gray-800;
$color-nav-link: $color-gray-800;
$color-bg-nav-link: $color-gray-100;
$color-bg-full-width-banner: $color-gray-500;
$color-link-text: $color-gray-700;
$color-title-price-plans: $color-tertiary;
$color-input-text-mobile: $color-tertiary;
$color-border-box-price-plans: #2fb39e;
$color-bg-cta: $color-secondary;
$color-bg-dot: $color-gray-800;
$color-text-underline: #cacaca;
$color-border-form-input: #b8b8b8;

$color-border-text: #b8b8b8;
$color-bg-carousel: #f06c37;
$color-bg-tab-carousel: #f0f0f0;
$color-border-text-carousel: #9b9b9b;
$color-spacing-text: #b7b7b7;
$color-input-mobile-placeholder: #b1b1b1;
$color-bg-price-text: $color-secondary;
$color-border-price-link: #a3aeb3;
$color-card-subtitle: $color-tertiary;
$color-bg-maitenance: #db6060;

$color-bg-table: #f0f0f0;
$color-box-shadow-table: rgba(0,0,0,.3);
$color-bg-table-scrollbar: darkgrey;

$color-background-facebook: #4267B2;
$color-background-twitter: #55acee;
$color-background-linkedin: #0077b5;
$color-background-whatsapp: #25d366;
$color-background-email: #D44638;
$color-background-hover-option: #39f;

$color-errors: #fe1718;
$color-border-image: #d7663a;
$color-point: #3a3a3a;
$color-border-input-search: #5bebd8;
$color-radio-plan-filter: #8d8d8d;

$color-rewards-filter: #e8f5f3;
