.slick-initialized {
  & .slick-dots {
    bottom: -40px;

    & li {
      &.hidden{
        display: none;
      }

      & button {
        &:before {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          opacity: 1;
          color: transparent;
          background-color: $color-bg-dot;
          border: 2px solid $color-title-price-plans;
        }
      }

      &.slick-active button:before {
        background-color: transparent;
      }
    }
  }

  @media #{$media-screen-mobile-normal} {
    & .slick-dots {
      & li,
      & button {
        width: 10px;
        height: 10px;
      }

      & li button:before {
        width: 10px;
        height: 10px;
      }
    }
  }
}
