.summary-electricity-plan {
  &__title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  &__check-referral-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__check-referral-desc {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
