.calc-step-3 {
  @extend .career-form-submit__radio-button;
  display: flex;
  padding-bottom: 0;

  &__radio-button-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;

    input {
      margin-bottom: 20px;
    }
  }

  @media #{$media-screen-mobile-normal} {
    justify-content: center;
  }
}
