.control-label {
  font-size: 18px;
  display: block;
  color: $color-tertiary;
  padding: 1rem 0;
}
.input-group.drop-down{
  display: inline-flex;

  .ws-errorbox{
    overflow: unset;
  }
}
.ws-errorbox p{
  font-family: Montserrat,sans-serif;
  color:$color-errors;
}
.form-response-msg{
  padding-top: 1rem;

  &.error{
    color:$color-errors;
  }

  &.success{
    color:#007353;
  }  
}
.icon-loading-spinner:before {
  display: inline-block;
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}