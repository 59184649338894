.life-plans {
  padding-bottom: 1px;

  &__slider {
    margin-right: -15px;
    margin-left: -15px;

    &.slick-dotted.slick-slider {
      margin-bottom: 50px;

      @media #{$media-md} {
        margin-bottom: 0;
      }
    }
  }

  & .price-plan-card {
    height: auto;

    &__modal, &__pdf {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }

    &__cta {
      display: flex;
      justify-content: center;
    }

    &__cta-download {

      @media #{$media-xl} {
        * + * {
          &:before {
            content: "";
          }
        }
      }
    }
  }

  &__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;

    &:focus {
      outline: none;
    }

    & .component {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      -o-flex-grow: 1;
      flex-grow: 1;
      height: 100%;
    }
  }

  @media #{$media-xl} {
    &__item {
      flex: 0 0 percentage(4/12);
      max-width: percentage(4/12);
    }

    & .col-desktop-3 {
      & .life-plans__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-desktop-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .life-plans__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }
  }

  @media #{$media-screen-tablet-desktop} {
    &__item {
      flex: 0 0 percentage(6/12);
      max-width: percentage(6/12);
    }

    & .col-tablet-3 {
      & .life-plans__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-tablet-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .life-plans__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }
  }

  @media #{$media-md} {
    &__slider {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      -o-justify-content: center;
      justify-content: center;
      align-content: stretch;
    }

    &__item {
      margin-bottom: 30px;
    }
  }

  @media #{$media-screen-mobile} {
    overflow: hidden;

    .container-fluid & {
      margin-left: - $grid-gutter-width / 2;
      margin-right: - $grid-gutter-width / 2;
    }

    & .slick-slide {
      margin-right: 0;
      height: auto;
    }

    & .slick-list {
      & .slick-track {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
      }
    }

    &__item {
      width: 330px;
      max-width: 330px;

      & .component {
        height: 100%;
      }
    }
  }

  @media #{$media-screen-mobile-normal} {
    & .slick-next,
    & .slick-prev {
      top: 25%;
    }
  }

  @media #{  $media-screen-mobile-small} {
    & .slick-next,
    & .slick-prev {
      top: 20%;
    }
  }
}
