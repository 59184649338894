.oem-plans {
  padding-bottom: 1px;

  &__slider {
    margin-left: -15px;
    margin-right: -15px;

    &.slick-dotted {
      margin-bottom: 50px;

      @media #{$media-md} {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    outline: none;
    height: auto;
    display: flex;

    & > .component {
      flex-grow: 1;
      display: flex;
    }
  }

  @media #{$media-md} {
    &__slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      flex: 0 0 percentage(4/12);
      max-width: percentage(4/12);
      margin-bottom: 30px;
    }
  }

  @media #{$media-xl} {
    &__item {
      flex: 0 0 percentage(4/12);
      max-width: percentage(4/12);
    }

    & .col-desktop-3 {
      & .oem-plans__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-desktop-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .oem-plans__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }
  }

  & .price-plan-card__cta {
    text-align: center;
  }

  @media #{$media-screen-tablet-desktop} {
    &__item {
      flex: 0 0 percentage(6/12);
      max-width: percentage(6/12);
    }

    & .col-tablet-3 {
      & .oem-plans__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-tablet-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .oem-plans__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }
  }

  @media #{$media-screen-mobile} {
    &__slider {
      margin-top: 0;
      margin-bottom: 0;

      .container-fluid & {
        margin-left: - $grid-gutter-width / 2;
        margin-right: - $grid-gutter-width / 2;
      }

      & .slick-track {
        display: flex;
      }
    }

    &__item {
      flex: 0 0 330px;
      max-width: 330px;
      width: 330px;
    }
  }
}
