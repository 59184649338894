// container
.container-fluid {
  max-width: $container-max-width;

}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

// text utilities
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.flex-center {
  justify-content: center;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.flex-middle {
  align-items: center;
}

// transform class using with element position not static
.transform-middle {
  top: 50%;
  transform: translateY(-50%);
}

.transform-center {
  left: 50%;
  transform: translateX(-50%);
}

.transform-mc {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mb-component {
  margin-bottom: 2rem;

  @media #{$media-md} {
    margin-bottom: 3rem;
  }
}

.mb-component-lg {
  margin-bottom: 2.5rem;

  @media #{$media-md} {
    margin-bottom: 4rem;
  }

  [class*=col-] > & {
    margin-bottom: 2.5rem !important;

    @media #{$media-md} {
      margin-bottom: 4rem !important;
    }
  }
}

.custom-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item-fullfill {
  flex: 1 0 auto;
}

@each $color, $value in $backgrounds {
  .bg-#{$color} {
    background-color: $value;
  }
}

.small, small {
  font-size: 80%;
}

.text-ellipsis {
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.hidden-scroll {
  overflow: hidden;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-semi-bold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

@keyframes menuAnimation {
  0%   {top: -100%;}
  100% {top: 0;}
}

.bg-custom {
  & .breadcrumbs {
    margin-left: -15px;
  }
}
