.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  & li {
    background: $gray-300;
    border-radius: 100%;
    margin-right: 20px;

    & a {
      text-decoration: none;
      padding: 6px $grid-gutter-width / 2;
      display: block;
      color: $gray-700;
    }

    &:hover {
      background: $color-input-placeholder;
    }
  }

  & li:first-child {
    border: none;
    background: none;
  }

  & li:last-child {
    border: none;
    background: none;
  }

  & .active {
    background: $color-secondary;

    & a {
      color: $color-white;
    }
  }
}
