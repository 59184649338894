.s-hidden {
  visibility:hidden;

  &:after {
    border: none;
  }
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 40px;
}

.styledSelect {
  position: absolute;
  top: 8px;
  right: 2px;
  bottom: 2px;
  left: 0;
  background-color: $color-white;
  padding:0 10px;
  font-weight: 600;
}

.styledSelect:after {
  content:"";
  width:0;
  height:0;
  border: 5px solid transparent;
  border-color: $color-black transparent transparent transparent;
  position: absolute;
  display: none;
}

.styledSelect:active, .styledSelect.active {
  background-color: $color-white;
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  right: -1px;
  left: -1px;
  z-index:999;
  margin: 0;
  padding: 0;
  list-style:none;
  border:1px solid $color-background-linkedin;
  background-color:white;
  -webkit-box-shadow:0 1px 2px $color-box-shadow-table;
  -moz-box-shadow:0 1px 2px $color-box-shadow-table;
  box-shadow:0 1px 2px $color-box-shadow-table;
}

.options li {
  padding:0 6px;
  margin: 0;
  padding:0 10px;

  &:hover {
    background-color: $color-background-hover-option;
    color: $color-white;
  }
}
