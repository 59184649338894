$color-border-tooltip: #B5A98B;

.tooltip-box {
  text-align: center;
  padding: 10px;
  border-radius: 2px;
  border: 2px solid $color-border-tooltip;
  position: absolute;
  background: $color-white;
  font-weight: 600;
  min-width: 100px;
  top: calc(100% + 20px);
  left: calc(-50% - 10px);
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    border-top: 2px solid $color-border-tooltip;
    border-left: 2px solid $color-border-tooltip;
    background: $color-white;
    top: -9px;
    left: 50%;
    transform: translateX(-50%) rotateZ(45deg);
  }

  &__items {
    display: block !important;
  }

  input {
    width: 100%;
    font-size: 14px;
    text-align: center;
    margin: 5px 0;

    &:last-child {
      font-weight: 700;
    }
  }

  @media #{$media-screen-mobile} {
    input {
      font-size: 14px;
    }

    padding: 5px;
    min-width: 90px;
    left: calc(-50% - 5px);
  }
}
