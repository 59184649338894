.tile-panel {
  border: none;
  border-radius: unset;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;

  &__link {
    text-decoration: none;
    display: block;
  }

  @media #{$media-md} {
    margin-bottom: 1rem;
  }

  @media #{$media-lg} {
    flex-direction: column;
    border: 6px solid $color-primary;
    border-radius: 45px;
    background: white;
  }

  &--color-theme {
    border-color: $color-white;
  }

  @each $color, $value in $theme-colors {
    &--border-#{$color} {
      border-color: $value;
    }
  }

  &--border-white {
    border-color: $color-white;
  }

  &--border-gray {
    border-color: map-get($grays, "300");
  }

  &:hover {
    border-color: map-get($grays, "300");
  }
  &:active {
    border-color: map-get($grays, "300");
  }

  &__wrapper {
    padding: 0;
    padding-left: $grid-gutter-width / 2;

    @media #{$media-lg} {
      padding-left: $grid-gutter-width;
      padding: 1rem 1rem 2rem;
    }
  }

  &__title {
    font-size: 1rem;

    [class*="bg-"] & {
      color: white;
    }

    @media #{$media-lg} {
      font-size: map-get($font-size, "size-5");
      color: $color-tertiary !important;
    }
  }

  &__image {
    flex: 0 0 percentage(4/12);

    @media #{$media-lg} {
      flex: 0 0 auto;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__desc {
    display: none;
    margin-top: 1rem;
    color: $color-text-body;

    [class*="bg-"] & {
      color: white;
    }

    @media #{$media-md} {
      display: block;
    }

    @media #{$media-lg} {
      display: block;
      color: $color-text-body !important;
    }
  }

  @media #{$media-not-desktop} {
    &__link {
      display: flex;
      width: 100%;
    }
  }
}
