.section-electricity {
  background: $color-primary;
  padding: 40px 0 50px 0;
  position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid $color-primary;
      bottom: -($grid-gutter-width / 2);
      left: 25%;
  }

  &__title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  &__desc {
    font-size: 20px;
    font-weight: 500;
  }
}
