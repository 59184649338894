.electricity {
  padding-bottom: 80px;

  &__list {
    display: flex;
  }

  &__left {
    flex: 1 0 auto;
    width: 320px;
    margin-right: $grid-gutter-width * 2;
  }

  &__right {
    flex: 1 1 auto;
  }

  &__button button:first-child , a:first-child {
    margin-right: $grid-gutter-width;
  }

  @media #{$media-screen-mobile} {
    &__list {
      flex-direction: column;
    }

    &__left {
      width: 100%;
      margin-right: 0;
    }

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & button:first-child , a:first-child {
        margin-right: 0;
        margin-bottom: $grid-gutter-width;
      }
    }
  }
}
