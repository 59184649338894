.accordion {
  &__list, &__item-year {
    color: $color-tertiary;
  }

  &__list {
    padding-bottom: 5px;
    border-bottom: 1px solid $color-border-text;
  }

  &__item-title {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    & > div:first-child:not(.accordion__item-year) {
      margin-left: 1rem;
    }
  }

  &__item-year {
    padding: 1rem;
    min-width: 75px;
    line-height: 1.2;
    text-align: right;
    font-size: map-get($font-size, "size-5");
    font-weight: 600;
    max-height: 60px;

    @media #{$media-md} {
      min-width: 110px;
      padding: 1rem;
      text-align: center;
    }

    + .accordion__box-item {
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: calc(100% - #{$grid-gutter-width / 2 + 75});
      min-width: 0;

      @media #{$media-md} {
        flex-basis: calc(100% - #{$grid-gutter-width / 2 + 110});
      }
    }
  }

  &__item-label {
    width: 100%;
    color: $color-text-body;

    + [class*="icon-"] {
      float: right;
      color: $color-primary;
      line-height: $line-height-base;
    }
  }

  &__box-item {
    width: 100%;
    margin-right: 1rem;
  }

  &__items {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
  }

  &__item-text {
    padding-bottom: 1rem;
    display: none;
  }

  &__item-line {
    height: 1px;
    width: 100%;
    background: $color-border-text;
  }

  & .active {
    display: block;
  }
}
