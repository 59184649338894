.section {
  &[class*="bg-"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &--layout[class*="bg-"] {
    @media #{$media-lg} {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    @media #{$media-lg} {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
}

// force theme
.content--inverse {
  color: white;

  .rich-text {
    color: white;
  }

  .c-sect-header {
    h1, h2, h3, h4, h5, h6, * {
      color: white;
    }
  }
}

.container--layout > .row > [class*="col-md-"] {
  + [class*="col-md-"] {
    // margin-top: 1rem;

    // @media #{$media-md} {
    //   margin-top: 0 !important;
    // }
  }
}

.container--layout > .row > [class*="col-lg-"] {
  + [class*="col-lg-"] {
    // margin-top: 1rem;

    // @media #{$media-lg} {
    //   margin-top: 0 !important;
    // }
  }
}

.row-reverse {
  flex-direction: row-reverse;
}

[class*="col-"] {
  > .component {
    margin-bottom: 1rem;
  }
}
