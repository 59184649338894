$footer-padding: 50px 0 30px 0;

.footer {
  padding: $footer-padding;
  background-color: #393939;
  font-family: $font-montserrat;

  @media #{$media-md} {
    background-image: url($image-path + 'bg-footer.png');
    background-position: top right;
    background-repeat: no-repeat;
  }

  &__nav, &__info {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-max-width;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  &__menu {
    @include column-gutter();
    flex: percentage(6/12) 1 0;
    margin-bottom: 2rem;

    @media #{$media-md} {
      flex: percentage(3/12) 1 0;
    }

    @media #{$media-xl} {
      flex: percentage(2/12) 1 0;
    }
  }

  &__menu-group {
    color: $color-white;
    font-size: 1.125rem;
    padding-bottom: 1.5rem;
    font-weight: bold;
  }

  &__menu-item {
    color: $color-white;
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    font-size: map-get($font-size, "size-7");
  }

  &__follow {
    & a {
      background: transparent;
      color: $color-white;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      margin-right: 1rem;
    }
  }

  &__copyright {
    font-size: map-get($font-size, "size-7");
    color: $color-white;
    padding-top: 1rem;
  }

  &__info {
    display: flex;
    flex-direction: column;

    @media #{$media-md} {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__content {
    @include column-gutter();
    order: 2;
    margin-top: 1rem;

    @media #{$media-md} {
      flex-basis: percentage(8/12);
      order: 1;
    }
  }

  &__brand {
    @include column-gutter();
    order: 1;
    margin-bottom: 1rem;

    @media #{$media-md} {
      flex-basis: percentage(4/12);
      order: 2;
      margin-bottom: 0;
      text-align: right;
    }
  }

  &__brand img {
    width: 225px;
    height: auto;

    @media #{$media-md} {
      max-width: 300px;
      width: 100%;
    }
  }
}
