.step-calculator {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid $color-gray-100;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.hide-before-arrow {
    &:before {
      border: none;
    }
  }

  &__title {
    &.heading-title {
      font-size: 30px;
    }

    &.order-step {
      font-size: 19.5px;
    }

    &.title-step {
      font-size: 16.5px;
      padding-left: 0;
    }
  }

  &__container {
    padding: 40px 50px;
    margin-top: 15px;
  }

  &__note {
    font-size: 16px;
    font-weight: 600;
    padding: 0 0 50px 50px;

    a {
      color: $color-primary;
    }
  }

  @media #{$media-screen-mobile} {
    &__note {
      padding: 0 0 50px;
    }

    &__title {
      padding-left: 15px;

      &.heading-title {
        font-size: 27px;
        padding: 0;
      }
    }
  }

  @media #{$media-screen-mobile-normal} {
    &__container {
      padding: 35px 15px;
    }
  }
}
