.filter-plan-cards {
  background: $color-gray-100;
  border-radius: 3px;

  &__tab-item {
    text-align: center;
    flex: 0 0 100%;
    font-size: 16px;
    padding: 15px 0;
    cursor: pointer;
  }

  &__item-text {
    @extend .font-semi-bold;
  }

  @media #{$media-md} {
    &__wrapper {
      display: flex;
      justify-content: center;
      width: 60%;
      margin: auto;

      & .active {
        border-bottom: 4px solid $color-secondary;
        display: inline-block;

        .filter-plan-cards__item-text {
          color: $color-tertiary;
        }
      }
    }

    &__wrapper-item {
      display: flex;
      justify-content: center;
    }

    &__dropdown-menu {
      width: 100%;
    }

    & .dropdown-menu {
      position: relative !important;
      display: block !important;
      top: 0 !important;
      transform: none !important;
      background-color: transparent !important;
      border: none !important;
      width: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    &__tab-item {
      display: inline-block;
      text-align: center;
      flex: 0 0 33.3333%;
      width: 33.3333%;
      font-size: 16px;
      padding: 15px 0;
      cursor: pointer;
    }
  }

  @media #{$media-screen-mobile} {
    &__tab-item {
      text-align: center;
      flex: 0 0 100%;
      max-width: 100%;
      font-size: 16px;
      padding: 0;
      display: block;
      width: 100%;
      padding: 15px 0;
    }

    &__dropdown-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    &__wrapper {
      position: relative;
      width: 100%;

      & .icon-arrow-down, .icon-arrow-up {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 35%;
      }

      & .dropdown-menu {
        padding: 0;
        margin: 0;
        top: 58px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }

      & .dropdown-item {
        padding: 10px 0;

        &:active {
          text-decoration: none;
          background-color: $color-primary;

          a {
            color: $color-white;
          }
        }
      }
    }

    &__tab-item-text {
      display: block;
      cursor: pointer;
      padding: 15px 50px;
      text-align: center;
      color: $color-tertiary;
      border-bottom: 4px solid $color-secondary;
    }
  }
}