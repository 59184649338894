$carousel-title-max: strip-unit(map-get($font-size, "size-1")) * $font-base;
$carousel-title-min: strip-unit(map-get($font-size, "size-4")) * $font-base;
$carousel-min-height: 350px;
$carousel-eclipse-size: 30%;
$carousel-info-width: 40%;

$carousel-info-width: 50%;

$carousel-theme-colors: (
  "theme-1": $color-primary,
  "theme-2": $color-secondary,
  "theme-3": $color-secondary
);

$carousel-desc-colors: (
  "theme-1": $color-text-body,
  "theme-2": $color-text-body,
  "theme-3": $color-white
);

$carousel-btn-colors: (
  "theme-1": $color-tertiary,
  "theme-2": $color-primary,
  "theme-3": $color-primary
);

.carousel {
  padding-bottom: 1px; // this to prevent margin collapse

  &__list {
    position: relative;
    overflow: hidden;
    font-family: $font-montserrat;

    &:before {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      left: 50%;
    }
  }

  &__list-bg-0 {
    &:before {
      background-color: transparent;
    }
  }

  &__list-bg-1,
  &__list-bg-2,
  &__list-bg-3,
  &__list-bg-4 {
    &:before {
      background-color: $color-bg-carousel;
    }
  }

  .nav-link {
    border-top: 1px solid transparent;
  }

  @each $color, $value in $carousel-theme-colors {
    &--#{$color} {
      background: $color-primary;
      color: $color-white;

      .carousel__title {
        @include text-block($value, $grid-gutter-width / 2);

      }

      .carousel__inner {
        &::before {
          background: $color-primary;
        }

        .carousel__info {
          & p {
            color: map-get($carousel-desc-colors, $color);
          }

          .carousel__btn {
            background: map-get($carousel-btn-colors, $color);
            border: 1px solid map-get($carousel-btn-colors, $color);
            color: $color-white;
          }
        }
      }
    }
  }

  &__item {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 50%;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__inner {
    @include make-container-fluid();
    position: relative;
    display: flex;

    @media #{$media-xl} {
      height: calc((400/960/2) * 100vw + 120px);
    }

    @media #{$media-md} {
      min-height: $masthead-min-height;
    }
  }

  &__tab {
    display: none;
    width: 100%;
    background: $color-bg-tab-carousel;

    @media #{$media-md} {
      display: block;
    }

    & .slick-track {
      transform: none !important;
    }
  }

  &__tab-content {
    &.slick-slider.slick-dotted {
      margin-bottom: 50px;

      @media #{$media-md} {
        margin-bottom: 0;
      }
    }

    .slick-current {
      border: none;
    }
  }

  &__submenu {
    @include make-container-fluid();
    @include column-gutter();

    display: flex;
    flex-direction: row;
    text-align: center;

    & .slick-current {
      position: relative;
      color: $color-tertiary;

      &::after {
        position: absolute;
        border-top: 6px solid $color-secondary;
        content: '';
        width: 100%;
        top: -15px;
        left: 0;
      }
    }

    & .slick-slide {
      border-right: 1px solid $color-border-text-carousel;
      margin: 15px 0;
      padding: 0 15px;
      height: auto;

      &:last-of-type {
        border-right: none;
      }
    }
  }

  &__link {
    width: 100%;
    font-size: map-get($font-size, 'size-7');
    color: $color-text-body;
    line-height: 1.3;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.slick-slide {
      display: flex;
      justify-self: stretch;

      span {
        align-self: center;
      }
    }
  }

  &__info {
    @include column-gutter();
    padding-right: 30px;
    z-index: 3;
    position: relative;
    flex: $carousel-info-width 0 0;
    max-width: $carousel-info-width;
    align-self: center;
    padding-top: $component-vertical-padding;
    padding-bottom: $component-vertical-padding;
    // font-size: map-get($font-size, "size-7");
  }

  &__info-content {
    position: relative;
  }

  &__title {
    cursor: pointer;
    margin-bottom: $btn-padding-x-sm;
    font-size: $carousel-title-min;

    @media #{$media-md} {
      font-size: calc(#{$carousel-title-min} + #{strip-unit($carousel-title-max - $carousel-title-min)} * (100vw - 768px) / (1200 - 768));
    }

    @media #{$media-xl} {
      font-size: $carousel-title-max;
    }
  }

  &__btn {
    margin-top: 1rem;
    @extend .btn;
  }

  .slick-track {
    display: flex;
    // height: 100%;
  }

  .slick-list.draggable {
    width: 100%;

    @media #{$media-md} {
      // margin-bottom: -6px;
    }
  }

  .slick-prev, .slick-next {
    top: 0;
    padding-bottom: percentage(9/16);
    transform: none;
    width: 30px;
    z-index: 3;

    &:before {
      @extend %icon;

      position: absolute;
      top: 50%;
      padding: 5px;
      transform: translate(-50%);
      font-size: 20px;
      background: $color-bg-nav-link;
      color: $color-tertiary;
      opacity: 1;
    }
  }

  .slick-prev {
    left: $grid-gutter-width / 2;

    &::before {
      content: $icon-arrow-left;
    }
  }

  .slick-next {
    right: $grid-gutter-width / 2;

    &::before {
      content: $icon-arrow-right;
    }
  }


  @media #{$media-screen-mobile} {
    height: auto;

    & .slick-track {
      background: $color-primary;
    }

    &__inner {
      height: auto;
    }

    &__info {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__list {
      height: auto;

      &:before {
        width: 0;
        content: unset;
      }
    }

    & .slick-slide {
      height: auto;
      display: flex;
    }

    &__item {
      padding-bottom: 50%;
      position: static;
      width: 100%;
      height: auto;
    }
  }
}
