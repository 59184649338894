.applicable-for-referral {
  &__list {
    padding: $grid-gutter-width $grid-gutter-width / 2;
  }

  &__title {
    @include fluid-type(22px, 24px);

    margin-bottom: 5px;
  }

  & .modal {
    display: none;
  }


  & .modal-close {
    right: 10px;
    top: 10px;
  }

  &__border {
    height: 1px;
    width: 100%;
    background: $color-text-body;
    margin-bottom: $grid-gutter-width - 10px;
  }

  &__desc {
    @include fluid-type(18, 22px);
  }

  &__button {
    margin-top: $grid-gutter-width / 2;
    & button {
      padding: 8px 30px;
      background: $color-white;
      display: inline-block;
      border: 1px solid $color-primary;
      color: $color-primary;
    }

    &--custom-button {
      & button {
        background: $color-white;
        display: inline-block;
        border: 1px solid $color-primary;
        color: $color-primary;
        padding: 8px 10px;
        cursor: pointer;
        margin: 0 10px;

      @media #{$media-screen-mobile-normal} {
        display: block;
        width: 100%;
        margin: 7px 0;
        }
      }

      @media #{$media-screen-mobile-normal} {
        & button {
          display: inline-block;
        }
      }
    }
  }
}
