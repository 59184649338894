.table-results {
  border: 6px solid $color-primary;
  background: $color-primary;
  color: $color-tertiary;
  border-radius: 45px;
  overflow: hidden;
  text-align: center;
  margin: 40px 0;

  &__heading {
    background: $color-primary;
    color: $color-white;
    font-size: map-get($font-size, "size-5");
    padding: 30px;
    font-weight: 700;
  }

  &__content-items {
    padding: 25px 10px;
    background: $color-white;
    font-size: 20px;

    &:nth-of-type(2n) {
      background: $color-gray-100;
    }
  }

  &__plans {
    font-weight: 700;
  }

  &__total {
    font-size: map-get($font-size, "size-5");
    font-weight: 600;
  }

  &__benefits {
    font-size: 16px;
    font-weight: 600;
  }

  &__main {
    font-size: 19px;
  }

  &__sub {
    font-size: 16px;
  }

  &__sign-in {
    button {
      font-size: 18px;
    }
  }

  @media #{$media-screen-mobile} {

    &__heading-items {
      font-size: 21px;
      font-weight: 700;
    }

    &__content {
      text-align: left;
    }

    &__content-items {
      padding: 30px;
    }
  }
}
