$media-text-image-title-max: strip-unit(map-get($font-size, "size-1")) * $font-base;
$media-text-image-title-min: strip-unit(map-get($font-size, "size-4")) * $font-base;
$media-text-image-eclipse-size: 30%;
$media-text-image-info-width: 50%;

.media-text-image {
  position: relative;
  overflow: hidden;

  &--theme-primary {
    background: map-get($backgrounds, 'primary');

    .media-text-image__btn {
      @extend .btn-tertiary;
    }
  }

  &--theme-gray {
    background: map-get($backgrounds, 'gray-100');
  }

  &--reverse {
    .media-text-image__inner {
      @media #{$media-md} {
        flex-direction: row-reverse;
      }
    }

    .media-text-image__image {
      @media #{$media-md} {
        left: 0;
        right: calc(50% + #{$grid-gutter-width / 2});
        width: calc(50% - #{$grid-gutter-width / 2});
        height: 320px;
        padding-bottom: 0;
      }

      @media #{$media-lg} {
        height: 0;
        padding-bottom: calc(#{percentage(400/960/2)} + 120px);
      }
    }
  }

  &__inner {
    @include make-container-fluid();
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @media #{$media-md} {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 50%;
      display: flex;
      flex-direction: row;
      width: 100%;
      transform: translate(-50%, 0);
    }
  }

  &__image {
    position: relative;
    padding-bottom: percentage(400/960);

    @media #{$media-md} {
      left: calc(50% + #{$grid-gutter-width / 2});
      width: calc(50% - #{$grid-gutter-width / 2});
      height: 320px;
      padding-bottom: 0;
    }

    @media #{$media-lg} {
      height: 0;
      padding-bottom: calc(#{percentage(400/960/2)} + 120px);
    }
  }

  &__info {
    @include column-gutter();
    z-index: 3;
    position: relative;
    flex: $media-text-image-info-width 0 0;
    max-width: $media-text-image-info-width;
    align-self: center;

    @media #{$media-lg} {
      padding-left: 37.5px;
      padding-right: 37.5px;
    }

    @media #{$media-xl} {
      padding-left: 75px;
      padding-right: 75px;
    }
  }

  &__btn {
    @extend .btn;
  }

  @media #{$media-screen-mobile}{
    &__info {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
