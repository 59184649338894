.c-sect-header {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $component-bottom;
  padding-top: 1px;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @media #{$media-md} {
    // occupy 10/12
    max-width: $container-max-width * 8 / 12;
    text-align: center;
  }

  // .section--layout[class*="bg-"] > .component:last-child > & {
  //   margin-bottom: 0;
  // }

  img {
    display: block;
    max-width: 100%;

    @media #{$media-md} {
      margin-left: auto;
      margin-right: auto;
    }
  }

  img + .c-sect-title {
    margin-top: 1rem;
  }

  span {
    &.white {
      color: $color-white;
    }
  }
}

.c-sect-title {
  margin-top: 2rem;
  margin-bottom: 1rem;

  // .section--layout[class*="bg-"] > .component:first-child > .c-sect-header > & {
  //   margin-top: 0;
  // }

  // .section--layout[class*="bg-"] > .component:last-child > .c-sect-header > & {
  //   margin-bottom: 0;
  // }
}
