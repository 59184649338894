.social-share {
  display: flex;

  &__lable {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  &__icon-facebook {
    background: $color-background-facebook;
    text-decoration: none;
  }

  &__icon-twitter {
    background: $color-background-twitter;
    text-decoration: none;
  }

  &__icon-linkedin {
    background: $color-background-linkedin;
    text-decoration: none;
  }

  &__icon-whatsapp {
    background: $color-background-whatsapp;
    text-decoration: none;
  }

  &__icon-email {
    background: $color-background-email;
    text-decoration: none;
  }

  &__icon {
    display: block;
    width: 25px;
  }

  &__link {
    padding: 10px 12px;
  }

  & .icon-facebook,
  & .icon-linkedin,
  & .icon-twitter,
  & .icon-whatsapp,
  & .icon-email {
    &:before {
      color: $color-white;
      font-size: 24px;
      vertical-align: middle;
    }
  }
}