@mixin ratio($ratio) {
  position: relative;
  width: 100%;
  padding-bottom: $ratio;

  & .element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-font-size, $max-font-size, $min-vw: 320px, $max-vw: 1200px) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin fluid-padding-top($min-width, $max-width, $min-vw: $min-vw, $max-vw: $max-vw) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-width);
  $u4: unit($max-width);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      padding-top: $min-width;

      @media screen and (min-width: $min-vw) {
        padding-top: calc(#{$min-width} + #{strip-unit($max-width - $min-width)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        padding-top: $max-width;
      }
    }
  }
}

@mixin fluid-padding-bottom($min-width, $max-width, $min-vw: $min-vw, $max-vw: $max-vw) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-width);
  $u4: unit($max-width);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      padding-bottom: $min-width;

      @media screen and (min-width: $min-vw) {
        padding-bottom: calc(#{$min-width} + #{strip-unit($max-width - $min-width)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        padding-bottom: $max-width;
      }
    }
  }
}

@mixin fluid-type-mobile($min-font-size, $max-font-size) {
  @include fluid-type($min-font-size, $max-font-size, 360px, 767px);
}

@mixin fluid-type-not-mobile($min-font-size, $max-font-size) {
  @include fluid-type($min-font-size, $max-font-size, 768px, 1200px);
}

@mixin fluid-width($min-width, $max-width, $min-vw: $min-vw, $max-vw: $max-vw) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-width);
  $u4: unit($max-width);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      width: $min-width;

      @media screen and (min-width: $min-vw) {
        width: calc(#{$min-width} + #{strip-unit($max-width - $min-width)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        width: $max-width;
      }
    }
  }
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin make-container-fluid {
  max-width: $container-max-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin column-gutter() {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
}

@mixin text-block($theme-bg, $padding) {
  padding-left: $padding;
  padding-right: $padding;

  span {
    color: white;
    line-height: 1.8;
    background-color: $theme-bg;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-shadow: inset 0 -10px 5px $theme-bg, #{$padding - 5px} 0 0 5px $theme-bg, inset 0 10px 5px $theme-bg, -#{$padding - 5px} 0 0 5px $theme-bg;
  }
}

%font-light {
  font-weight: 300;
}

%font-regular {
  font-weight: 400;
}

%font-semi-bold {
  font-weight: 600;
}

%font-bold {
  font-weight: 700;
}
