.referral-code {
  border: 6px solid $color-primary;
  border-radius: $grid-gutter-width;
  padding: 35px $grid-gutter-width 45px $grid-gutter-width;

  &__list {
    max-width: 680px;
  }

  &__title {
    font-size: 26px;
    margin-bottom: $grid-gutter-width / 2;
  }
}
