.modal {
  padding: 0 17px;

  &-header {
    position: relative;
    display: block;
    text-align: center;
  }

  h5 {
    margin-top: 5px;
  }

  &-body {
    padding: 20px 0;
  }

  &-dialog {
    max-width: 750px;
  }

  &-content {
    padding: 0 30px;
    color: $color-text-body;
    border: 6px solid $color-primary;
    border-radius: 30px;
  }

  &-close {
    z-index: 1;
    position: absolute;
    background: transparent;
    border: none;
    right: -10px;

    .icon-close:before {
      font-size: 20px;
      font-weight: bold;
      color: $color-tertiary;
      cursor: pointer;
    }
  }
}