.input-check-submit {
  &__input {
    display: flex;
    flex-direction: row;

    &--max-width {
      align-items: center;
      justify-content: center;
    }
  }

  &__notification {
    font-size: 16px;
    margin-top: 5px;
  }

  &__notification.error {
    color: $color-errors;
  }

  &__notification.success {
    color: inherit;
  }

  & input {
    height: 46px;
    border: 1px solid $color-gray-300;
    max-width: 360px;
    border-radius: 4px 0 0 4px;

    &:focus {
      box-shadow: none;
    }
  }

  &__cta {
    min-width: 80px;
    border: 1px solid $color-primary;
    background: $color-white;
    border-radius: 0 4px 4px 0;
    font-size: 16px;
    color: $color-primary;
    padding: 10px 15px;
    text-decoration: none;
    cursor: pointer;

    & .icon-loading-spinner {
      &:before {
        display: inline-block;
        -webkit-animation:icon-spin 2s infinite linear;
        animation:icon-spin 2s infinite linear;
      }
    }

    @-webkit-keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
      }
      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
      }
    }
  }
}
