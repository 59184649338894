// break points
$break-point-375: 375px;
$break-point-576: 576px;
$break-point-768: 768px;
$break-point-992: 992px;
$break-point-1024: 1024px;
$break-point-1200: 1200px;
$break-point-1366: 1366px;

$media-screen-mobile-small: '(max-width: #{$break-point-375 - 1})';
$media-screen-mobile-normal: '(max-width: #{$break-point-576 - 1})';
$media-screen-mobile: '(max-width: #{$break-point-768 - 1})';
$media-screen-tablet: '(min-width: #{$break-point-768}) and (max-width: #{$break-point-992 - 1})';
$media-screen-desktop: '(min-width: #{$break-point-992}) and (max-width: #{$break-point-1200 - 1})';
$media-screen-tablet-desktop: '(min-width: #{$break-point-768}) and (max-width: #{$break-point-1200 - 1})';
$media-large-desktop: '(min-width: #{$break-point-1366})';
$media-not-desktop: '(max-width: #{$break-point-992 - 1})';

$media-xs: '(max-width: #{$break-point-576 - 1})';
$media-sm: '(min-width: #{$break-point-576})';
$media-md: '(min-width: #{$break-point-768})';
$media-lg: '(min-width: #{$break-point-992})';
$media-xl: '(min-width: #{$break-point-1200})';

$grid-gutter-width: 30px;
$thick-border-width: 6px;

// font variables
$font-montserrat: 'Montserrat', sans-serif;
$font-family-base: $font-montserrat;

// font-size-base
$font-base: 16px;

$container-max-width: 1140px;
$component-height-masthead: 500px;

$component-top: 1rem;
$component-bottom: 2rem;

$component-vertical-padding: 1.5rem;

$content-horizontal-padding: $grid-gutter-width / 2;
$content-vertical-padding: 1rem;

$font-size: (
  "size-1": 2.125rem,   // 34px 36px
  "size-2": 1.875rem,   // 30px 32px
  "size-3": 1.625rem,   // 26px 28px
  "size-4": 1.375rem,   // 22px
  "size-5": 1.125rem,   // 18px
  "size-6": 1rem,       // 16px
  "size-7": 0.875rem,   // 14px
  "size-8": 0.75rem     // 12px
);

$min-vw: 360px;
$max-vw: 1140px;

$btn-border-radius:           3rem;
$btn-border-radius-lg:        3rem;
$btn-border-radius-sm:        3rem;

$btn-font-weight:             600;

$btn-padding-y-sm:            .25rem;
$btn-padding-x-sm:            1rem;
$btn-padding-y:               .5rem;
$btn-padding-x:               1.5rem;
$btn-padding-x-lg:            1.75rem; //$input-btn-padding-y-lg !default;
$btn-padding-y-lg:            .5rem; //$input-btn-padding-x-lg !default;

$link-color:                  $color-tertiary;
$link-hover-color:            $color-tertiary;
$input-placeholder-color:     $color-border-form-input;

$headings-line-height:        1.2;
$z-index-select: 3;
