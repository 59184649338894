.popover {
  top: 15px !important;
}

.popover-body {
  padding: 0;
}

.popover .arrow {
  position: relative;
  height: 0;
  // border: 1px solid $color-spacing-text;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: -2px;
  border-width: 0 0.5rem 0.8rem 0.5rem;
  border-bottom-color: $color-white;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .active-arrow::after {
  border-bottom-color: $color-border-box-price-plans;
}

@media #{$media-not-desktop} {
  .popover {
    position: fixed !important;
    top: 65px !important;
    z-index: 2500;
    transform: none !important;
    max-width: 100%;
    width: 100%;
  }

  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    top: 0px;
    border-width: 0;
    border-bottom-color: transparent;
  }

  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::before {
    top: 0px;
    border-width: 0;
    border-bottom-color: transparent;
  }
}
