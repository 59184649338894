.electricity-range {
  padding: 12px 14px;
  background: white;
  border-radius: 30px;
  margin: 50px 0 100px;

  @media #{$media-screen-mobile} {
    margin: 30px 25px 94px;
  }
}

#electricity-range__input {
  width: calc(100% - 20px);
  height: 25px;
  background-image: -moz-linear-gradient( 178deg, rgb(241,95,36) 0%, rgb(216,211,153) 26%, rgb(47,179,158) 52%, rgb(0,57,73) 100%);
  background-image: -webkit-linear-gradient( 178deg, rgb(241,95,36) 0%, rgb(216,211,153) 26%, rgb(47,179,158) 52%, rgb(0,57,73) 100%);
  background-image: -ms-linear-gradient( 178deg, rgb(241,95,36) 0%, rgb(216,211,153) 26%, rgb(47,179,158) 52%, rgb(0,57,73) 100%);
  background-size: cover;
  background-position: center;

  .tooltip {
    position: relative;
    margin-left: 0!important;
    transition: 0.2s;
  }

  .slider-track-high,
  .slider-track-low {
    background: white;
    height: 25px;
    border-radius: 0;
    transition: 0.2s;
  }

  .slider-selection {
    background: transparent;
    box-shadow: none;
  }

  .slider-track {
    height: 25px;
    top: 0;
    margin-top: 0;
    background: transparent;
  }

  .slider-handle {
    background-image: url($image-path + 'button-range.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    transform: translateY(-20%);
    top: 0;
    transition: 0.2s;
    outline: none;
  }
}
