
@font-face {
  font-family: 'Montserrat';
  src: url($font-path + 'Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: url($font-path + 'Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url($font-path + 'Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url($font-path + 'Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

