.career-title {
  padding: $grid-gutter-width 0;

  &__title {
    margin-bottom: 25px;
  }

  &__content p {
    margin-bottom: 0.5rem;
  }
}
