$padding-left-list: 0;
$padding-left-label: 2rem;
$padding-tab-menu: $padding-left-list + $padding-left-label;
$margin-20: 1.25rem;
$padding-20: 1.25rem;
$border-radius-menu: 0 0 10px 10px;

.list-menu-item {
  .group-checkbox {
    label {
      display: block;
      position: relative;
      font-weight: 600;
      cursor: pointer;
    }

    & input[type="checkbox"] {
      display: none;
    }

    & input[type="checkbox"] + label:before {
      border: 1px solid $color-border-box-price-plans;
      background-color: transparent;
      content: "\00a0";
      display: inline-block;
      font-size: 20px;
      line-height: 1;
      height: 22px;
      width: 22px;
      vertical-align: top;
      transition: .3s;

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.is-checked .group-checkbox input[type=checkbox] + label:before {
    background-color: transparent;
    color: $color-white;
    content: "\2713";
    text-align: center;
  }
}

.price-plan-filter {
  color: $color-gray-800;
  background: $color-gray-100;
  border-radius: 3px;

  button:focus,
  input:focus {
    color: $color-gray-800;
    outline: 0;
  }

  &__wrapper {
    width: 100%;
  }

  &__menu {
    padding: 9px 0;
  }

  &__menu-item {
    &:not(.w-100) {
      position: relative;

      &:not(:first-child) {
        padding-right: 10px;
      }

      &:not(:last-child) {
        display: inline-block;
      }

      &:first-child {
        padding-left: 12px;
        padding-right: 35px;
      }

      & > a {
        display: inline-block;
        padding: 15px;
      }

      &.show .icon-arrow:before {
        content: $icon-arrow-up;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: $color-white;
    padding: 6px 20px;
    cursor: pointer;

    &[aria-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0 5px 16px 1px rgba(0, 0, 0, .1);
    }

    .icon-arrow {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 13px;

      &:before {
        content: $icon-arrow-down;
      }
    }
  }

  &__description {
    @include fluid-type(13px, 16px, 480px, 991px);

    display: block;
    font-weight: 400;
    white-space: normal;
    cursor: pointer;
  }

  .group-checkbox {
    margin-left: $margin-20;
  }

  .dropdown-menu {
    border: none;
    border-radius: $border-radius-menu;
    background: $color-white;
    margin: 0;
    padding: 0;
    width: calc(100% - 10px);
    top: 100%!important;
    transform: none !important;
    box-shadow: 0 16px 16px 1px rgba(0, 0, 0, .1);
  }

  &__description {
    padding-left: $padding-left-label + $padding-20;
    padding-right: $padding-20;
  }

  .dropdown-item {
    color: $color-tertiary;
    padding: .625rem 0;

    &:first-child {
      .group-checkbox input[type=checkbox] + label:before {
        display: none;
      }
    }

    &:not(:first-child) {
      .group-checkbox label {
        padding-left: 33px;
      }
    }

    &.is-checked:last-child,
    &:last-child:focus,
    &:last-child:hover {
      border-radius: $border-radius-menu;
    }
  }

  &--single {
    $padding-left-label: 0;
    $padding-tab-menu: $padding-left-list + $padding-left-label;

    .group-checkbox input[type=checkbox] + label:before {
      display: none;
    }

    .dropdown-menu .dropdown-item div.group-checkbox label,
    .price-plan-filter__description {
      padding-right: $padding-20;
      padding-left: $padding-20;
    }

    .dropdown-item {
      & .group-checkbox {
        margin-left: 0;
      }

      &.is-checked,
      &:focus,
      &:hover {
        color: $color-white;
        background-color: $color-secondary;

        .group-checkbox input[type=checkbox]+label:before {
          border: 1px solid $color-white;
        }
      }

      &.is-checked:focus,
      &.is-checked:hover {
        .group-checkbox input[type=checkbox]+label:before {
          color: $color-white;
        }
      }
    }
  }

  &--multiple {
    .dropdown-item {

      &.active,
      &:active {
        background-color: transparent;
      }

      &.is-checked {
        color: $color-tertiary;
        background-color: $color-white;

        .group-checkbox input[type=checkbox]+label:before {
          border: 1px solid $color-border-box-price-plans;
          background-color: $color-border-box-price-plans;
        }
      }

      &:focus,
      &:hover {
        color: $color-white;
        background-color: $color-secondary;
      }
    }
  }

  @media #{$media-md} {
    &__menu-item:not(.w-100) {
      &:last-child {
        display: none;
      }
    }

    &__button {
      @include fluid-type-not-mobile(13px, 16px);
    }
  }

  @media #{$media-xl} {
    &__menu-item:not(.w-100) {
      &:not(:first-child) {
        width: 317px;
      }
    }
  }

  @media (max-width: $break-point-1200 - 1) {
    &__menu-item:not(.w-100) {
      &:not(:first-child) {
        width: 260px;
      }
    }
  }

  @media #{$media-screen-mobile} {
    &__menu {
      display: flex;
    }

    &__menu-item {
      &:not(.is-show-mobile) {
        display: none !important;
      }

      &:last-child {
        flex: 1 1 auto;

        & button {
          margin: 0;
        }
      }

      &:first-child,
      &:last-child {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }

    &__button {
      @include fluid-type-mobile(13px, 16px);
    }
  }
}

.modal-price-filter {
  color: $color-tertiary;

  p {
    margin: 0;
  }

  button:focus {
    color: $color-white;
  }

  .price-plan-filter__description {
    padding-left: $padding-left-label;
  }

  .list-menu-item .group-checkbox label {
    padding-left: $padding-left-label;
  }

  .modal-dialog {
    pointer-events: auto;
  }

  .modal-header {
    background-color: $color-border-box-price-plans;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .modal-content {
    padding: 0;
    border: 6px solid transparent;
    border-radius: 20px;
  }

  .price-plan-title {
    position: relative;
    color: $color-white;

    span {
      @include fluid-type(20px, 26px, 480px, 991px);

      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      color: $color-white;
      cursor: pointer;
    }
  }

  .price-plan-menu {
    padding: 0 30px;

    &__item {
      margin-bottom: 30px;
    }

    &__text {
      padding-bottom: 20px;
    }
  }

  .price-plan-dropdown {
    color: $color-tertiary;

    & > li {
      margin-bottom: 10px;

      label {
        @include fluid-type(13px, 16px, 480px, 991px);
      }
    }
  }

  &--single {
    .price-plan-dropdown {
      .list-menu-item.is-checked .group-checkbox input[type=checkbox]+label:before {
        color: $color-border-box-price-plans;
      }

      .group-checkbox input[type=checkbox] + label:before {
        content: ' ';
        border-radius: 50%;
        top: 55%;
      }

      li.is-checked .group-checkbox input[type=checkbox] + label {
        &:after {
          content: ' ';
          position: absolute;
          left: 4.5px;
          top: 55%;
          transform: translateY(-50%);
          width: 13px;
          height: 13px;
          background: $color-border-box-price-plans;
          border-radius: 50%;
        }
      }
    }
  }

  &--multiple {
    .list-menu-item {
      &.is-checked .group-checkbox input[type=checkbox]+label:before {
        color: $color-white;
        background-color: $color-border-box-price-plans;
      }
    }
  }

  .modal-footer {
    justify-content: center;
    padding: 0 0 30px;
    border-top: none;
  }
}
