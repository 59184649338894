.rewards {
  padding-bottom: 1px;

  &__item {
    outline: none;
    cursor: pointer;
    display: flex;

    & > .component {
      flex-grow: 1;
      display: flex;
    }

    &.hidden{
      display: none;
    }
  }

  &__bot-line {
    margin-top: 15px;
  }

  & .slick-initialized {
    & .slick-track {
      display: flex;

      & .slick-slide {
        height: auto;
      }
    }
  }

  & .price-plan-card__border {
    &:hover {
      border-color: lighten(map-get($theme-colors, "primary"), $color-hover-lighten);
    }
  }

  & .price-plan-card__price-desc {
    text-align: left;
  }

  &__modal {
    & .modal-header {
      margin: 20px 0 0 0;
      padding: 0;
      border-bottom: none;
    }

    & .modal-close {
      right: -25px;
      top: -8px;

      & .icon-close:before {
        font-size: 15px;
      }
    }

    & .modal-title {
      @include fluid-type-mobile(16px, 22px);

      color: $color-tertiary;
      margin-top: $grid-gutter-width / 2;
    }
  }

  &-category{
    &__title{
      font-size: 22px;
      text-align: center;
      margin-bottom: 1rem;

      .icon-plus,
      .icon-minus{
        display: none;
      }
    }
    
    &__list{
      display: block;
    }

    &__items{
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      font-size: .875rem;      
      padding: 0 $grid-gutter-width / 2;

      li{
        color: $color-border-box-price-plans;
        background-color: $color-white;
        border-radius: 20px;
        border: 3px solid $color-border-box-price-plans;
        padding: 0.5rem 1rem;
        margin-right: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &:hover{
          color: $color-white;
          background-color:lighten(map-get($theme-colors, "primary"), $color-hover-lighten);
          border-color: lighten(map-get($theme-colors, "primary"), $color-hover-lighten);
        }

        &.active{
          background-color: $color-border-box-price-plans;
          border-color: $color-border-box-price-plans;
          color: $color-white;
        }
      }       
    }
    
  }

  @media #{$media-md} {
    &__slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      flex: 0 0 percentage(4/12);
      max-width: percentage(4/12);
      margin-bottom: 30px;
    }    
  }

  @media #{$media-xl} {
    &__item {
      flex: 0 0 percentage(4/12);
      max-width: percentage(4/12);
    }

    & .col-desktop-3 {
      & .rewards__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-desktop-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .rewards__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }

    .rewards-category {
      &__items{
        font-size:  $font-base;
      }
    }
    
  }

  @media #{$media-screen-tablet-desktop} {
    &__item {
      flex: 0 0 percentage(6/12);
      max-width: percentage(6/12);
    }

    & .col-tablet-3 {
      & .rewards__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-tablet-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .rewards__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }

  }

  @media #{$media-screen-mobile} {
    .container-fluid & {
      margin-left: - $grid-gutter-width / 2;
      margin-right: - $grid-gutter-width / 2;
    }

    &__slider {
      &.slick-dotted.slick-slider {
        margin-bottom: 50px;
      }
    }

    &__item {
      &--width {
        width: 330px;
      }
    }
  }

  @media #{$media-screen-tablet}{
    .rewards-category {
      margin-right: -1rem;
      margin-left: -1rem;
    }
  }

  @media #{$media-not-desktop} {
    .rewards-category {
      background: $color-rewards-filter;

      &__title{    
        display: flex;
        align-items: center;    
        text-align: left;   
        padding: 1rem 1rem;  

        .icon-plus,
        .icon-minus{
          display: block;
          margin-left: auto;
        }
      }  

      &__list{
        display: none;
        padding-bottom: 1rem;
      }

      &__items{
        justify-content: flex-start;
      }
    }
  }
}
