.price-plans {
  // &__heading {
  //   margin: 20px 0 10px;
  //   text-align: center;
  // }

  &__item {
    outline: none;

    & > .component {
      height: 100%;
    }
  }

  // &__text {
  //   font-size: 16px;
  //   color: $color-gray-800;
  //   max-width: 41%;
  //   margin: 0 auto 25px;
  // }

  &__cta {
    text-align: center;

    & a {
     font-weight: 700;

     &:focus {
       outline: none;
     }
    }
  }

  & .slick-dotted.slick-slider {
    margin-bottom: 60px;
  }

  @media #{$media-md} {
    &__slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      margin-bottom: 30px;
      flex: 0 0 33.3333%;
    }
  }

  @media #{$media-xl} {
    &__item {
      flex: 0 0 percentage(4/12);
      max-width: percentage(4/12);
    }

    & .col-desktop-3 {
      & .price-plans__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-desktop-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .price-plans__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }
  }

  @media #{$media-screen-tablet-desktop} {
    &__item {
      flex: 0 0 percentage(6/12);
      max-width: percentage(6/12);
    }

    & .col-tablet-3 {
      & .price-plans__item {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }

    & .col-tablet-2 {
      & .price-plan-card__border {
        width: 350px;

        &.is-margin-left {
          margin-left: auto;
        }

        &.is-margin-right {
          margin-right: auto;
        }

        &.is-margin-auto {
          margin: auto;
        }
      }

      & .price-plans__item {
        flex: 0 0 percentage(6/12);
        max-width: percentage(6/12);
      }
    }
  }

  @media #{$media-screen-mobile} {
    overflow: hidden;

    .container-fluid & {
      margin-left: - $grid-gutter-width / 2;
      margin-right: - $grid-gutter-width / 2;
    }

    & .slick-initialized {
      & .slick-track {
        display: flex;
      }

      & .slick-slide {
        height: auto;
      }
    }

    &__cta {
      & a {
        font-size: 16px;
      }
    }

    &__item {
      width: 330px;
    }
  }

  @media #{$media-screen-mobile-normal} {
    &__cta {
      & a {
        margin: 0 0 10px 20px;
        padding: 10px 30px;
      }
    }
  }
}
