@mixin rich-text() {
  h1, h2, h3, h4, h5, h6 {
    color: $color-tertiary;

    @each $color, $value in $theme-colors {
      &.heading--theme-#{$color} {
        @include text-block($value, $grid-gutter-width / 2);
      }
    }
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  .btn {
    margin-top: 1rem;
  }

  @each $color, $value in $theme-colors {
    a.rte-btn-#{$color} {
      @extend .btn;
      @include button-variant(
        $value, $value,
        lighten($value, $color-hover-lighten), lighten($value, $color-hover-lighten),
        lighten($value, $color-active-lighten), lighten($value, $color-active-lighten)
      );

      @include hover {
        color: white;
      }
    }
  }

  a.btn-primary,
  a.btn-tertiary,
  a.btn-secondary {
    color: $color-white;
  }

  a {
    color: $color-tertiary;
  }

  img {
    max-width: 100%;
    height: auto !important;
  }

  ol, ul {
    // padding-inline-start: 40px;
    // padding-start: 40px;
    padding-left: 20px;
    list-style-position: outside;
    margin-bottom: 1rem;
  }

  ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  th {
    text-align: left;
  }

  .table-responsive {
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
    border: 10px solid $color-white;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,.3);
    margin-bottom: 1rem;

    &--full {
      display: block;
      min-width: 100%;
      padding-bottom: 1rem;
    }

    &--stack {
      display: block;
      min-width: 100%;
      padding-bottom: 1rem;

      @media #{$media-screen-mobile} {
        border: 0;
        padding-bottom: 0;
        box-shadow: none;
        overflow: visible;
      }
    }
  }

  table.table-primary {
    border-collapse: collapse;

    &::-webkit-scrollbar {
      height: 10px;
      width: 5px;
      cursor: pointer;
      background: $color-bg-table;
      box-shadow: 0 -1px 0 0 $color-bg-table;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-bg-table;
      outline: 1px solid $color-bg-table;
    }

    thead tr {
      background-color: $color-primary;
      color: $color-white;
      font-weight: 700;

      th, td {
        padding: 15px 20px;
      }
    }

    tbody {
      background-color: $color-bg-table;

      tr {
        border-bottom: 2px solid $color-primary;

        &:last-child {
          border-bottom: none;
        }
      }

      td {
        padding: 15px 20px;
        color: $body-color;
      }
    }

    @media #{$media-lg} {
      &:hover::-webkit-scrollbar-thumb {
        background-color: $color-bg-table-scrollbar;
      }
    }

    @media #{$media-not-desktop} {
      &::-webkit-scrollbar-thumb {
        background-color: $color-bg-table-scrollbar;
        border-radius: 10px;
      }
    }
  }

  table.table-primary--full {
    @extend .table-primary;
    min-width: 100%;
  }

  table.table-primary--stack {
    @extend .table-primary;
    min-width: 100%;
    border-collapse: separate;
    border-radius: 30px;
    border: 6px solid $color-primary;
    border-spacing: 0;

    @media #{$media-screen-mobile} {
      width: 100% !important;
    }

    th {
      border: 1px solid $color-primary;
    }

    th:first-child {
      border-radius: 20px 0 0 0;
    }

    th:last-child {
      border-radius: 0 20px 0 0;
    }

    @media #{$media-screen-mobile} {
      tbody {
        tr > td {
          border-radius: 0;
        }

        tr:first-child > td:first-child {
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
        }

        tr:last-child > td:last-child {
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 30px;
        }
      }
    }

    @media #{$media-md} {
      tbody {
        tr:first-child > td:first-child {
          border-top-left-radius: 30px;
        }

        tr:first-child > td:last-child {
          border-top-right-radius: 30px;
        }
      }

      thead + tbody {
        tr:first-child > td:first-child {
          border-top-left-radius: 0;
        }

        tr:first-child > td:last-child {
          border-top-right-radius: 0;
        }
      }

      tr:last-child > td:first-child {
        border-bottom-left-radius: 30px;
      }

      tr:last-child > td:last-child {
        border-bottom-right-radius: 30px;
      }
    }

    tbody {
      background: none;
    }

    tbody > tr {
      &:nth-child(even) td {
        background: $color-gray-100;
      }

      &:nth-child(odd) td {
        background: white;
      }

      @media #{$media-screen-mobile} {
        display: block;
      }

      > td {
        @media #{$media-screen-mobile} {
          &:before {
            content: attr(data-header);
            display: block;
            color: $color-primary;
            font-weight: 700;
          }

          display: block;
          width: 100% !important;
        }
      }
    }

    thead {
      @media #{$media-screen-mobile} {
        display: none;
      }

      @media #{$media-md} {
        tr {
          background-color: transparent;
        }

        th {
          background-color: $color-primary;
        }
      }
    }
  }
}

