.nav-search {
  &__list {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    padding-right: 15%;
  }

  &__wrap {
    background: $color-navigation-background;
  }

  &__logo {
    display: flex;
    padding-right: $grid-gutter-width / 2;

    & img {
      width: 100px;
    }
  }

  &__login {
    display: inline-block;
    padding: 3px 15px;

    a {
      text-decoration: none;
      font-size: $font-base;
      color: $color-white;
    }
  }

  &__icon {
    & .icon-search {
      font-size: 25px;
      color: $color-white;
      display: block;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  &__login-link {
    display: block;
  }

  &__brand {
    padding: 10px 0;

    img {
      display: block;
    }
  }

  &__sub-link {
    display: flex;
    align-content: center;
    padding: 24.5px 0;
  }

  &__sub-link-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  & .dropdown-item:hover {
    background-color: transparent;
  }

  .navbar-custom .navbar-nav .nav-link {
    color: $color-social-icon;
  }

  .navbar-custom .nav-link:hover {
    @extend .font-semi-bold;
    color: $color-tertiary;
  }

  &__sub-list-links {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
    width: 100%;
    justify-content: flex-start;

    & a {
      text-decoration: none;
      color: $color-sub-item-menu;

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &__sub-link-item {
    padding: 0 $grid-gutter-width / 2;
    font-size: map-get($font-size, "size-7");
  }

  & .navbar {
    padding: 0;
  }

  & .active {
    a {
      color: $color-white;
    }
  }

  & .sub-active {
    color: $color-secondary;
    @extend .font-semi-bold;
  }

  &__item {
    flex: 0 0 auto;
    display: flex;
    font-size: map-get($font-size, "size-7");
    flex-direction: column;
    justify-content: center;

    +.nav-search__item {
      margin-left: 1.5rem;
    }

    & a {
      color: $color-text-nav-search;
      text-decoration: none;
    }

    & .is-show {
      display: block;
    }
  }

  &__icon {
    display: inline-block;

    & img {
      float: right;
      clear: both;
      cursor: pointer;
    }
  }

  & form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid $color-white;
    margin-bottom: 15px;
  }

  &__form {
    width: 100%;

    & button {
      background: transparent;
      border: none;
      padding-left: 0;
      padding-right: 15px;

      & .icon-search {
        color: $color-white;
        font-size: 22px;
        cursor: pointer;
      }
    }

    & .icon-close {
      color: $color-white;
      font-size: 22px;
      cursor: pointer;
    }

    input[type="text"] {
      border: none;
      background: transparent;
      flex: 1 0 auto;
      color: $color-white;
      font-size: $font-base;

      &::placeholder {
        color: $color-input-placeholder;
        font-size: $font-base;
      }

      &:focus {
        border: none;
        background-color: transparent;
        outline: none;
      }
    }
  }

  &__login-wrapper {
    background-color: $color-white;
    padding: 15px;
    border: 1px solid $color-spacing-text;
    border-top: none;

    &:hover {
      background-color: $color-border-box-price-plans;
      color: $color-white;
      padding: 15px;
      cursor: pointer;

      & .nav-search__login-wrapper-title {
        color: $color-white;
        text-decoration: none;
      }

      & .nav-search__login-wrapper-sub-title {
        color: $color-white;
      }
    }
  }

  &__login-popovers {
    width: 230px;

    a {
      text-decoration: none;
    }

    & .active {
      background-color: $color-border-box-price-plans;
      color: $color-white;
      padding: 15px;
      cursor: pointer;

      & .nav-search__login-wrapper-title {
        color: $color-white;
        text-decoration: none;
      }

      & .nav-search__login-wrapper-sub-title {
        color: $color-white;
      }
    }
  }

  &__login-board-info {
    max-width: 230px;
    font-size: 16px;

    p {
      padding: 0;
    }
  }

  &__login-wrapper-title {
    margin-bottom: 15px;
    color: $color-tertiary;
    text-decoration: underline;

    @extend .font-semi-bold;
  }

  &__login-wrapper-sub-title {
    margin-bottom: 0;
    color: $color-gray-800;
  }

  .icon-dynamic {
    display: inline-block;

    @media #{$media-lg} {
      display: none;
    }
  }

  &__box-toggle {
    display: flex;
  }

  &__icon-toggle-list {
    position: relative;
    cursor: pointer;

    &.toggle-indicator {
      span {
        @extend .icon-arrow-down;
      }

      &.expanded {
        span {
          @extend .icon-arrow-up;
        }
      }
    }

    &:hover {
      color: $color-white;
    }
  }

  & .navbar-light .navbar-toggler-icon {
    width: 40px;
    height: 23px;
    background-image: none;
    position: relative;
    border-bottom: 5px solid $color-tertiary;
    transition: all 100ms linear;

    &:after,
    &:before {
      width: 40px;
      position: absolute;
      border-radius: 5px;
      height: 5px;
      background-color: $color-tertiary;
      top: -1px;
      left: 0;
      content: "";
      z-index: 2;
      transition: all 100ms linear;
    }

    &:after {
      top: 8px;
    }
  }

  @media #{$media-lg} {
    &__login {
      margin: 10px 0;
    }

    & .navbar-expand-lg .navbar-collapse {
      justify-content: flex-end;
    }
  }

  @media #{$media-xl} {
    &__item, &__sub-link-item {
      font-size: $font-base;
    }

    &__logo {
      img {
        width: 100%;
      }
    }
  }

  @media #{$media-not-desktop} {
    &__form {
      padding-left: 15px;
      padding-right: 15px;

    & input[type="text"] {
      color: $color-input-text-mobile;

      &::placeholder {
        color: $color-input-mobile-placeholder;
        font-size: $font-base;
      }
    }

    & button {
      padding-right: 10px;

      & .icon-search {
        color: #b1b1b1;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }

  &__logo {
    padding-right: 0;

    & img {
      @include fluid-width(115px, 175px, 768px, 992px);
    }
  }

  &__link-text {
    display: inline-block;
    width: calc(100% - 30px);
  }

  &__icon-toggle-list {
    display: inline-block;
    width: 30px;
  }

  &__login {
    & a {
      color: $color-tertiary;
      display: inline-block;
      padding: 3px 15px;
      border-radius: 5px;
    }
  }

  &__icon {
    display: inline-block;

    & img {
      float: right;
      clear: both;
      cursor: pointer;
    }
  }

  &__list {
    padding-right: 5%;
  }

  &__sub-link-item {
    @include fluid-type(strip-unit(map-get($font-size, "size-8")) * $font-base,
      strip-unit(map-get($font-size, "size-6")) * $font-base,
      768px,
      992px);
  }

  &__login-wrapper {
    border-bottom: 1px solid $color-spacing-text;
  }

  &__icon {
    flex-basis: 5%;
  }

  &__login {
    & a {
      color: $color-tertiary;
      display: inline-block;
      padding: 7px 12px;
      border: 2px solid $color-tertiary;
      border-radius: 5px;
    }
  }

  &__mobile-top {
    @include column-gutter();
    display: flex;
    margin: auto;
    width: 100%;
    align-items: center;
  }

  &__form {
    padding-top: 20px;
    margin: auto;
    width: 100%;
  }

  & form {
    border-bottom: 1px solid $color-tertiary;
  }

  &__brand {
    flex: 1 0 auto;

    img {
      max-width: 120px;
    }
  }

  &__item {
    a {
      color: $color-gray-800;
    }
  }

  &__wrap {
    background: $color-white;
  }

  &__item {
    width: 100%;
    padding-top: 30px;

    +.nav-search__item {
      margin-left: 0;
    }
  }

  &__dropdown {
    padding-top: 20px;
    padding-left: 15px;
    display: none;

    & a {
      color: $color-text-body;
    }
  }

  &__icon-list {
    padding-left: 25px;
  }

  &__not-icon-list {
    padding-left: 30px;
  }

  &__list {
    @include column-gutter;
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 0;
  }

  &__collapse {
    background: $color-show-menu-background;
    height: 100vh;
    overflow-y: scroll;
  }

  &__icon-toggle-list {
    &:hover {
      color: $color-gray-800;
    }
  }

  &__sub-link-item {
    & a {
      font-size: $font-base;
    }
  }

  &__mobile-top-bar {
    height: 15px;
    width: 100%;
    background: $color-tertiary;
  }

  & .active {
    & a,
    & .icon-dynamic {
      color: $color-sub-item-menu;
    }

    & ul {
      display: block;
    }
  }

  & .navigation-wrap {
    background: $color-white;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1050;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  &__login-board-info {
    max-width: 100%;
  }

  &__login-popovers {
    width: 100%;
  }

  &__login-wrapper {
    background-color: $color-gray-100;
  }

  & .container-fluid {
    padding: 0;

    & .navigation-wrap {
      background: $color-white;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1050;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;

      &.is-fixed {
        position: fixed;
        -webkit-animation: menuAnimation 0.3s;
        animation: menuAnimation 0.3s;
      }
    }
  }

  .sub-active {
    color: $color-secondary !important;
  }
}

  @media #{$media-screen-mobile-normal} {
    &__collapse {
      max-height: calc(100vh - 85px);
    }
  }
}

.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
  padding-left: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  &[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
  }

  &[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    top: 0;
  }

  &[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
  }
}
